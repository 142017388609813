export const EVENTS = {
  OPEN_CHAT: "openChat",
  CLOSE_CHAT: "closeChat",
};

export const CONSTANTS = {
  WIDGET_SETTINGS: {
    STYLE: "style",
    SIDEBAR: "sidebar",
    MAIN: "main",
  },
};
